import Cookies from "universal-cookie";
import { useState, useMemo } from "react";
import "../styles/esi-call-queue-count-button.css";

export const EsiCallQueueCountButton = () => {
	const cookies = useMemo(() => new Cookies(), []);
	const [callsInQueue, setCallsInQueue] = useState("");
	const [callsLoading, setCallsLoading] = useState(false);

	const getCallsInQueue = async (e) => {
		try {
			setCallsLoading(true);

			const callQueueCountResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/esi-aaa-ivr-get-calls-in-queue`, {
				method: "GET",
				redirect: "follow",
				headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
			});
			const callQueueCountData = await callQueueCountResponse.json();
			console.log(callQueueCountData);

			setCallsInQueue(callQueueCountData.message);
			setCallsLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<button type="button" className="widget" onClick={getCallsInQueue}>
			{callsInQueue !== "" ? (
				<div className="label">
					Calls in Queue
					<p className="value">{callsInQueue} </p>
					<div>{callsLoading ? <span className="load"></span> : <p></p>}</div>
				</div>
			) : (
				<div className="label">
					Check Calls in Queue
					<div>{callsLoading ? <span className="load"></span> : <p></p>}</div>
				</div>
			)}

			<svg viewBox="-5 -5 110 110" preserveAspectRatio="none" aria-hidden="true">
				<path d="M0,0 C0,0 100,0 100,0 C100,0 100,100 100,100 C100,100 0,100 0,100 C0,100 0,0 0,0" />
			</svg>
		</button>
	);
};
