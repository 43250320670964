import Cookies from "universal-cookie";
import DatePicker from "react-date-picker";
import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { LoadingModal } from "./LoadingModal.jsx";
import { ConfirmationModal } from "./ConfirmationModal.jsx";
import { PTPException } from "../utils/models/ptpException.js";
import { isValidAaaHitlistRequest } from "../utils/scripts/inputValidations.js";
import simpleFormStyles from "../styles/simple-form.module.css";
import "react-calendar/dist/Calendar.css";
import "../styles/react-date-picker.css";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import PharmacyCustomizer from "./PharmacyCustomizer.jsx";
import NDCCheckBox from "./NDCCheckBox.jsx";
import { config } from "react-transition-group";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#455660",
        },
        secondary: {
            main: "#1f9eef",
        },
    },
});

const SelectInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(1),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #black",
        paddingTop: theme.spacing(2),
        fontSize: 16,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
            color: "white",
        },
    },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "& .MuiFormControlLabel-root": {
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #black",
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(1),
        fontSize: 16,
        borderRadius: "4px",

        transition: "background 0.37s",
        "&:hover": {
            backgroundColor: theme.palette.action.selected,
            transition: "background 0.29s",
        },
    },

    "& .MuiFormLabel-root": {
        color: "black",
        fontWeight: "bold",
        marginLeft: "2rem",
        fontSize: 12,
        zIndex: 0,
        textAlign: "left",
        transition: "color 0.37s",
        "&.Mui-focused": {
            color: theme.palette.secondary.main,
            transition: "color 0.29s",
        },
    },

    "& .MuiCheckbox-root": {
        color: theme.palette.secondary.main,
        "&.Mui-checked": {
            color: theme.palette.secondary.main,
        },
    },
}));

export default function AAAHitlistInput() {
    const cookies = useMemo(() => new Cookies(), []);
    const aaaProcessMode = useSelector((state) => state.aaaProcess.processMode);
    const programName = useSelector((state) => state.program.name);

    //Viv's Testing
    const [ndcs, setNdcs] = useState([]);
    const [selectedNdcs, setSelectedNdcs] = useState([]);
    const [useNdcCheckbox, setUseNdcCheckbox] = useState(false);

    // const [date, setDate] = useState(((dt) => new Date(dt.setDate(dt.getDate() - 1)))(new Date()));
    const [dateRangeStart, setDateRangeStart] = useState(((dt) => new Date(dt.setDate(dt.getDate() - 1)))(new Date()));
    const [dateRangeEnd, setDateRangeEnd] = useState(((dt) => new Date(dt.setDate(dt.getDate() - 1)))(new Date()));
    const [reportType, setReportType] = useState("All");
    const [executingProcess, setExecutingProcess] = useState(false);
    const [confirmationModalActive, setConfirmationModalActive] = useState(false);
    const [state, setState] = useState({
        CHAINS: false,
        SP_CHAINS: false,
    });
    const { CHAINS, SP_CHAINS } = state;
    const [customNpis, setCustomNpis] = useState([]);
    const [errorText, setErrorText] = useState([""]);
    const handleFilterChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const handleDeleteClick = (e) => () => {
        let holderNpis = [...customNpis];
        if (holderNpis.length === 1) {
            setCustomNpis([]);

            return;
        } else {
            let newNpis = holderNpis.slice(0, e).concat(holderNpis.slice(e + 1, holderNpis.length));
            console.log(newNpis);
            console.log("x");
            setCustomNpis(newNpis);
        }
        return;
    };

    const handleAddClick = (e) => () => {
        // console.log(indexes.length);
        // console.log(Object.keys(customNpis).slice(-1));
        let newPharmacy = { npi: "", pharmacyName: "" };
        let newNpis = [...customNpis, newPharmacy];
        setCustomNpis(newNpis);
        // setCustomNpis.push(newPharmacy);
    };

    const runAaaDispatchProcess = async (e) => {
        try {
            setExecutingProcess(() => true);
            console.log("runAAADispatch.");
            const body = {
                program: programName,
                params: {
                    dateRangeStart: dateRangeStart,
                    dateRangeEnd: dateRangeEnd,
                    reportType: reportType,
                    FILTERS: state,
                    customNpis: customNpis,
                    selectedNdcs: selectedNdcs,
                    useNdcCheckbox: useNdcCheckbox,
                },
                aaaProcess: aaaProcessMode,
            };

            // should throw here
            const aaaDispatchProcessErrorCheck = isValidAaaHitlistRequest(body);
            if (aaaDispatchProcessErrorCheck) throw new PTPException(aaaDispatchProcessErrorCheck);

            const aaaDailyResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-process-dispatcher`, {
                method: "POST",
                body: JSON.stringify(body),
                headers: { Authorization: `Bearer ${cookies.get("_auth")}` },
            });

            if (!aaaDailyResponse.ok) throw new PTPException("Failed to run AAA daily");
            // const aaaDailyData = await aaaDailyResponse.json();

            console.log(aaaDailyResponse);
            displayConfirmationModal();
        } catch (err) {
            console.log(err);
            alert(err.name === "PTPException" ? err.message : "Failed to run AAA daily");
        } finally {
            setExecutingProcess(() => false);
        }
    };

    const runDevConfig = async (e) => {
        try {
            const data = {
                program: programName,
            };

            const aaaConfigResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-config-data`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    // "Access-Control-Allow-Origin": "*",
                    // Accept: "application/json",
                    Authorization: `Bearer ${cookies.get("_auth")}`,
                },
            });

            setNdcs(await aaaConfigResponse.json());

            //Testingh
            console.log(aaaConfigResponse);
            console.log(ndcs);
        } catch (err) {
            console.log(err);
        }
    };

    //Viv's Testing
    const handleSelectedNdcs = (e) => {
        setUseNdcCheckbox(true);
        setState({
            ...state,
            [e.target.value]: e.target.checked,
        });

        let newNdc = { ndc: e.target.value, drugName: e.target.name };
        let newSelectedNDCs = [...selectedNdcs, newNdc];
        let holderNDCS = [...selectedNdcs];

        if (e.target.checked === true) {
            //console.log("yay we got chosen!");
            //console.log(newSelectedNDCs);
            setSelectedNdcs(newSelectedNDCs);
        } else if (e.target.checked === false) {
            //console.log("Yikes, guess not.");
            //console.log(e.target.value);

            var index = holderNDCS.findIndex((i) => i.ndc === e.target.value);
            //console.log(index);

            holderNDCS.splice(index, 1);
            newSelectedNDCs = [...holderNDCS];
            setSelectedNdcs(newSelectedNDCs);
        }
        //console.log(newSelectedNDCs);
    };

    useEffect(() => {
        //resets selectedNdcs and ndcCheckbox when rendered
        setNdcs({});
        setSelectedNdcs([]);
        runDevConfig();
    }, [, programName, reportType]);

    const displayConfirmationModal = () => {
        setConfirmationModalActive(() => true);

        setTimeout(() => {
            setConfirmationModalActive(() => false);
        }, 2500);
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <div className="centered-container">
                {executingProcess && <LoadingModal text="Dispatching process..." />}
                {confirmationModalActive && <ConfirmationModal text="Process successfully dispatched"></ConfirmationModal>}
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={reportType === "All" || reportType === "Target" ? 5 : 6}>
                        <form className={simpleFormStyles.simpleForm}>
                            <div className={simpleFormStyles.simpleFormInputContainer}>
                                <label className={simpleFormStyles.simpleFormLabel}>Date Processed:</label>
                                <DatePicker onChange={(newDate) => setDateRangeStart(() => newDate)} value={dateRangeStart} minDate={new Date("2000-01-01")} />
                                <DatePicker onChange={(newDateB) => setDateRangeEnd(() => newDateB)} value={dateRangeEnd} minDate={new Date("2000-01-01")} />
                            </div>

                            <Box textAlign="center" sx={{ display: "flex" }}>
                                <FormControl
                                    sx={{
                                        margin: "auto",
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "50%",
                                        paddingLeft: "2rem",
                                    }}
                                >
                                    <InputLabel sx={{ color: "black", fontWeight: "bold", fontSize: 16, zIndex: 0 }}>Report Type</InputLabel>
                                    <Select
                                        id="reportTypes"
                                        label="reportTypes"
                                        onChange={(e) => setReportType(e.target.value)}
                                        value={reportType}
                                        input={<SelectInput />}
                                    >
                                        <MenuItem value="All" sx={{ fontSize: 12 }}>
                                            All
                                        </MenuItem>
                                        <MenuItem value="Target" sx={{ fontSize: 12 }}>
                                            Target
                                        </MenuItem>
                                        <MenuItem value="Target+Custom" sx={{ fontSize: 12 }}>
                                            Target + Custom
                                        </MenuItem>
                                        <MenuItem value="Custom" sx={{ fontSize: 12 }}>
                                            Custom
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {/* <Box
                                sx={{
                                    margin: "auto",
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    width: "50%",
                                    paddingLeft: "2rem",
                                }}
                            > */}
                                <StyledFormControl
                                    sx={{
                                        margin: "auto",
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "50%",
                                        paddingLeft: "2rem",
                                    }}
                                    component="fieldset"
                                    variant="standard"
                                >
                                    <FormLabel component="legend">Chains Filters</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={CHAINS} onChange={handleFilterChange} name="CHAINS" />} label="Chains" />
                                        <FormControlLabel
                                            control={<Checkbox checked={SP_CHAINS} onChange={handleFilterChange} name="SP_CHAINS" />}
                                            label="Sp Chains"
                                        />
                                    </FormGroup>
                                </StyledFormControl>
                                {/* </Box> */}
                            </Box>

                            <div className={simpleFormStyles.simpleFormSubmitContainer}>
                                <button type="button" className="run" onClick={runAaaDispatchProcess}>
                                    Run
                                </button>
                            </div>
                        </form>
                    </Grid>
                    {(reportType === "Custom" || reportType === "Target+Custom") && (
                        <PharmacyCustomizer
                            customNpis={customNpis}
                            setCustomNpis={setCustomNpis}
                            handleAddClick={handleAddClick}
                            handleDeleteClick={handleDeleteClick}
                            errorText={errorText}
                            setErrorText={setErrorText}
                        ></PharmacyCustomizer>
                    )}
                    {(reportType === "All" || reportType === "Target") && <NDCCheckBox ndcs={ndcs} handleSelectedNdcs={handleSelectedNdcs}></NDCCheckBox>}
                </Grid>
            </div>
        </ThemeProvider>
    );
}
