import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { LoadingModal } from "../components/LoadingModal.jsx";
import { ConfirmationModal } from "./ConfirmationModal.jsx";
import { isValidS3Upload } from "../utils/scripts/inputValidations.js";
import { PTPException } from "../utils/models/ptpException.js";
import simpleFormStyles from "../styles/simple-form.module.css";

export default function IvrBatch({ cookies, processor, ivrType }) {
    const programName = useSelector((state) => state.program.name);
    const [file, setFile] = useState(null);
    const inputFileRef = useRef();
    const [uploadLoading, setUploadLoading] = useState(false);
    const [confirmationModalActive, setConfirmationModalActive] = useState(false);

    const uploadToS3 = async (e) => {
        try {
            setUploadLoading(() => true);

            const s3UploadErrorCheck = isValidS3Upload(file, programName);
            if (s3UploadErrorCheck) throw new PTPException(s3UploadErrorCheck);

            const urlResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ivr-batch-get-s3-presigned-url`, {
                method: "POST",
                body: JSON.stringify({
                    filename: file.name,
                    program: ivrType === "aaa" ? programName : processor,
                    processor: processor,
                    ivrType: ivrType,
                }),
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
            });

            const urlData = await urlResponse.json();
            const uploadUrl = urlData.url;

            const uploadResponse = await fetch(uploadUrl, {
                method: "PUT",
                body: file,
            });
            if (urlResponse.status === 400) throw new PTPException(urlData.message);

            if (!uploadResponse.ok) throw new PTPException("File could not be uploaded at this time");

            displayConfirmationModal();
        } catch (err) {
            console.log(err);
            alert(err.name === "PTPException" ? err.message : "File could not be uploaded at this time");
        } finally {
            inputFileRef.current.value = "";
            setFile(() => null);
            setUploadLoading(() => false);
        }
    };

    const displayConfirmationModal = () => {
        setConfirmationModalActive(() => true);

        setTimeout(() => {
            setConfirmationModalActive(() => false);
        }, 2500);
    };

    return (
        <div className="centered-container">
            {uploadLoading === true && <LoadingModal text="Uploading file..." />}
            {confirmationModalActive && <ConfirmationModal text="Batch successfully processed"></ConfirmationModal>}
            <form className={simpleFormStyles.simpleForm}>
                <div className={simpleFormStyles.simpleFormInputContainer}>
                    <label className={simpleFormStyles.simpleFormLabel}>File:</label>
                    <input
                        className={simpleFormStyles.simpleFormFileInput}
                        type="file"
                        name="file"
                        accept=".xlsx"
                        onChange={(e) => {
                            setFile(() => e.target.files[0]);
                        }}
                        ref={inputFileRef}
                    ></input>
                </div>
                <div className={simpleFormStyles.simpleFormSubmitContainer}>
                    <button type="button" className="run" onClick={uploadToS3}>
                        Upload
                    </button>
                </div>
            </form>
        </div>
    );
}
